<template>
<Dialog v-model:visible="displayModal" header="Cadastro de Pessoa" :breakpoints="{ '960px': '25vw', '640px': '20vw' }" :style="{ width: '70vw' }" :modal="true" class="bg-dark navbar-nav">
    <Message :closable="false" severity="error" v-if="erros && erros.length > 0">
        <ul class="mt-3">
            <li :key="erro" v-for="erro in erros">{{ erro }}</li>
        </ul>
    </Message>
    <div class="container text-left">
        <div class="row d-flex align-items-end">
            <div class="col-md-3 mb-2">
                <label>{{this.tipoFiltro}} *</label>
                <span class="p-fluid">
                    <InputText id="documento" v-model="documento" type="text" disabled />
                </span>
            </div>

            <div class="col-md-6 mb-2">
                <label>Nome: *</label>
                <span class="p-fluid">
                    <InputText v-model="nome" type="text" />
                </span>
            </div>
            <div class="col-md-6 mb-2" v-if="this.tipoFiltro == 'CNPJ'">
                <label>Nome Fantasia:</label>
                <span class="p-fluid">
                    <InputText id="nomeFantasia" v-model="nomeFantasia" type="text" />
                </span>
            </div>
        </div>

        <hr />

        <div class="row d-flex align-items-end">
            <div class="col-md-3 mb-2">
                <label>CEP: *</label>
                <span class="p-fluid">
                    <div class="p-inputgroup">
                        <InputMask id="cep" v-model="cep" @blur="searchLocalidadePorCep()" mask="99.999-999" placeholder="Pesquisar" :disabled="bloquearEdicaoEndereco" />
                        <Button @click="searchLocalidadePorCep()" type="button" icon="pi pi-search" class="p-button" title="Pesquisar CEP" :disabled="bloquearEdicaoEndereco" />
                    </div>
                </span>
            </div>

            <div class="col-md-3 mb-2">
                <label for="munic">Município: *</label>
                <span class="p-fluid">
                    <AutoComplete id="munic" v-model="selectedMunicipio" :dropdown="true" :suggestions="filteredLocalidades" @item-select="setLocalidades($event)" @complete="searchLocalidade($event)" field="detail" placeholder="Pesquisar" :disabled="bloquearEdicaoEndereco" />
                </span>
            </div>

            <div class="col-md-3 mb-2">
                <label>Estado:</label>
                <span class="p-fluid">
                    <InputText id="estado" v-model="nomeEstado" type="text" disabled />
                </span>
            </div>

            <div class="col-md-3 mb-2">
                <label>País:</label>
                <span class="p-fluid">
                    <InputText id="pais" v-model="nomePais" type="text" disabled />
                </span>
            </div>

            <div class="col-md-3 mb-2">
                <label>Bairro: *</label>
                <span class="p-fluid">
                    <AutoComplete id="bairr" v-model="selectedBairro" :dropdown="true" :suggestions="filteredBairros" @item-select="setBairro($event)" @complete="searchBairro($event)" field="nomeBairro" placeholder="Pesquisar" :disabled="bloquearEdicaoEndereco" />
                </span>
            </div>

            <div class="col-md-5 mb-2">
                <label>Logradouro: *</label>
                <span class="p-fluid">
                    <AutoComplete id="logra" v-model="selectedLogradouro" :dropdown="true" :suggestions="filteredLogras" @item-select="setLogradouro($event)" @complete="searchLogradouro($event)" placeholder="Pesquisar" field="detail" :disabled="bloquearEdicaoEndereco">
                        <template #item="slotProps">
                            <div class="p-fluid" style="max-width: 50vw">
                                {{ slotProps.item.detail }}
                            </div>
                        </template>
                    </AutoComplete>
                </span>
            </div>

            <div class="col-md-2 mb-2">
                <label>Número: *</label>
                <span class="p-fluid">
                    <InputText id="numero" v-model="numero" type="text" :disabled="bloquearEdicaoEndereco" />
                </span>
            </div>

            <div class="col-md-6 mb-2">
                <label>Complemento:</label>
                <span class="p-fluid">
                    <InputText id="complemento" v-model="complemento" type="text" maxlength="40" :disabled="bloquearEdicaoEndereco" />
                </span>
            </div>
        </div>

        <hr />

        <div class="row d-flex align-items-end">
            <div class="col-md-6 mb-2">
                <label>E-mail: *</label>
                <span class="p-fluid">
                    <InputText id="email" v-model="email" type="text" />
                </span>
            </div>

            <div class="col-md-3 mb-2">
                <label>Telefone:</label>
                <span class="p-fluid">
                    <InputMask id="telefone" v-model="telefone" mask="(99) 9999-9999" />
                </span>
            </div>

            <div class="col-md-3 mb-2">
                <label>Celular:</label>
                <span class="p-fluid">
                    <InputMask id="celular" v-model="celular" mask="(99) 99999-999?9" />
                </span>
            </div>
        </div>
    </div>
    <hr />
    <div class="container">
        <div class="row">
            <div>
                <Button @click="finalizarCredenciar()" label="Finalizar" class="p-button-primary mr-2" icon="pi pi-check" :disabled="credenciadoComSucesso" />
                <Button @click="hideCredenciar()" type="button" label="Fechar" class="p-button-primary mr-2 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </div>
</Dialog>
<Dialog header="Seleção Endereço" v-model:visible="showSelectLogra" :modal="true" :closable="false">
    <div class="container scrolable-dialog selecao-contribuinte">
        <div class="row">
            <DataTable :value="logradouroList" :paginator="true" :rows="5" style="min-width: 100%">
                <Column field="detail" header="Endereço:"></Column>
                <Column headerStyle="width: 58px" bodyStyle="text-align: center">
                    <template #body="objeto">
                        <Button type="button" icon="pi pi-check" title="Selecionar" @click="preencherDadosLogradouro(objeto.data)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <hr />

    <div class="container">
        <div class="row">
            <div>
                <Button @click="showSelectLogra = false" type="button" label="Fechar" class="p-button-primary mr-2 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </div>
</Dialog>
<input type="hidden" v-model="tipoFiltro" />
</template>

<script>
import CadastroPessoaService from "@/services/cadastro.pessoa.service";
import DocumentoService from "@/services/documento.service";

export default {
    name: "cadastro",

    data() {
        return {
            displayModal: false,
            erro: null,
            erros: [],
            selectedMunicipio: null,
            bloquearEdicaoEndereco: false,
            filteredLocalidades: null,
            selectedBairro: null,
            filteredBairros: null,
            filteredLogras: null,
            selectedLogradouro: null,
            credenciadoComSucesso: false,
            showSelectLogra: false,
            documento: "",
            nome: "",
            cep: "",
            munic: "",
            estado: "",
            pais: "",
            bairro: "",
            logra: "",
            numero: "",
            complemento: "",
            email: "",
            telefone: "",
            celular: "",
            tipoFiltro: "",
            nomeFantasia: "",
            loading: false,
        };
    },
    created() {

    },

    mounted() {},
    computed: {
        idCliente() {
            return this.$store.getters.getIdCliente;
        },
        nomeEstado() {
            return this.selectedMunicipio && this.selectedMunicipio.estado ?
                this.selectedMunicipio.estado.detail :
                "";
        },

        nomePais() {
            return this.selectedMunicipio &&
                this.selectedMunicipio.estado &&
                this.selectedMunicipio.estado.pais ?
                this.selectedMunicipio.estado.pais.detail :
                "";
        },
    },
    methods: {
        show() {
            this.displayModal = true;
        },

        hideCredenciar() {
            this.displayModal = false;
            this.documento = null;
            this.nome = null;
            this.cep = null;
            this.munic = null;
            this.estado = null;
            this.pais = null;
            this.bairro = null;
            this.logra = null;
            this.numero = null;
            this.complemento = null;
            this.email = null;
            this.telefone = null;
            this.celular = null;
            this.tipoFiltro = null;
            this.selectedMunicipio = null;
            this.selectedLogradouro = null;
            this.selectedBairro = null;
        },

        searchLocalidadePorCep() {
            if (this.cep !== "") {
                this.processando(true);
                let cep = this.cep;
                cep = cep.replace(".", "");
                cep = cep.replace("-", "");
                CadastroPessoaService.findAllLograByCep(cep)
                    .then(
                        (response) => {
                            if (response.data.length && response.data.length > 1) {
                                this.logradouroList = response.data;
                                this.showSelectLogra = true;
                            } else if (response.data.length > 0) {
                                this.selectedCepLogra = response.data[0];
                                this.preencherDadosLogradouro(this.selectedCepLogra);
                            }
                        },
                        (error) => {
                            let errorMessage = "Houve um problema ao listar Logradouros!";
                            if (error.response) {
                                errorMessage = error.response.data;
                            }
                            this.alerta('error', errorMessage);
                        }
                    )
                    .then(() => {
                        this.processando(false);
                    });
            }
        },

        searchLogradouro(event) {
            setTimeout(async () => {
                this.filteredLogras =
                    await CadastroPessoaService.findAllLogradouroByFiltro(
                        this.idCliente,
                        event.query
                    );
            }, 250);
        },

        searchLocalidade(event) {
            setTimeout(async () => {
                this.filteredLocalidades =
                    await CadastroPessoaService.findAllLocalidadeByFiltro(event.query);
            }, 250);
        },

        preencherDadosLogradouro(logradouro) {
            this.processando(true);
            this.cep = logradouro.cepLogradouro;
            //this.solicitacaoCadastro.bairro = logradouro.nomeBairro;
            //this.solicitacaoCadastro.tipoLogra = logradouro.nomeTipoLogra;
            //this.solicitacaoCadastro.logradouro = logradouro.nomeLogradouro;
            CadastroPessoaService.findAllById(logradouro.idCepLocalidade)
                .then((response) => {
                    this.selectMunicipioSede(response.data);
                })
                .then(() => {
                    this.processando(false);
                    this.showSelectLogra = false;
                });
        },

        selectMunicipioSede(municipio) {
            this.selectedMunicipio = municipio;
        },

        setLocalidades(event) {
            this.processando(true);
            this.selectedMunicipio = event.value;
        },
        processando(show) {
            if (show) {
                this.loading = true;
                this.$root.$emit("loading:show");
            } else {
                this.loading = false;
                this.$root.$emit("loading:hide");
            }
        },

        setBairro(event) {
            this.processando(true);
            this.selectedBairro = event.value;
            this.processando(false);
        },

        setLogradouro(event) {
            this.processando(true);
            this.selectedLogradouro = event.value;
            this.processando(false);
        },

        searchBairro(event) {
            setTimeout(async () => {
                this.filteredBairros =
                    await CadastroPessoaService.findAllBairroByFiltro(
                        this.idCliente,
                        event.query
                    );
            }, 250);
        },

        finalizarCredenciar() {
            this.loading = true;
            this.erros = [];
            if (this.documento == null || this.documento == "") {
                this.erros.push('Informe um número de documento válido');
            }

            if (this.nome == null || this.nome == "") {
                this.erros.push('O Nome deve ser informado');
            }
            if (this.cep == null || this.cep == "") {
                this.erros.push('O CEP deve ser informado');
            }
            if (!this.selectedMunicipio) {
                this.erros.push('O Municipio deve ser informado');
            }
            if (!this.selectedBairro) {
                this.erros.push('O Bairro deve ser informado');
            }
            if (!this.selectedLogradouro) {
                this.erros.push('O Logradouro deve ser informado');
            }
            if (!this.numero) {
                this.erros.push('O Número deve ser informado');
            }
            if (!this.email) {
                this.erros.push('O Email deve ser informado');
            }

            const dados = Promise.resolve(
                DocumentoService.findPessoa(this.idCliente, this.documento)
            );
            dados.then((retorno) => {
                if (retorno) {
                    this.alerta('error', this.tipoFiltro + ' ' + this.documento + ' já cadastrado!');
                    return false;
                }
            });

            CadastroPessoaService.finalizarCadastro({
                idCliente: this.$store.getters.getIdCliente,
                documento: this.documento,
                nome: this.nome,
                cep: this.cep,
                municipioNome: this.selectedMunicipio.detail,
                estadoNome: this.selectedMunicipio.estado.detail,
                paisNome: this.selectedMunicipio.estado.pais.detail,
                idBairro: this.selectedBairro.id,
                bairro: this.selectedBairro.nomeBairro,
                idLogradouro: this.selectedLogradouro.id,
                logradouro: this.selectedLogradouro.detail,
                idCepLocalidade: this.selectedMunicipio.id,
                numero: this.numero,
                complemento: this.complemento,
                email: this.email,
                telefone: this.telefone,
                celular: this.celular,
                tipo: this.tipoFiltro,
                nomeFantasia: this.nomeFantasia,

            }).then(
                (response) => {
                    console.log(response);
                    this.credenciadoComSucesso = true;
                    this.alerta('success', 'Cadastro efetuado com sucesso!');
                    this.loading = false;
                },
                (error) => {
                    const erros = error.response.data;
                    this.erros.push(...erros);
                    this.loading = false;
                }
            );

        },

        alerta(type, msg) {
            this.$toast.add({
                severity: type,
                summary: msg,
                life: 3000,
            });
        },
    },
};
</script>

<style scoped>
.login {
    width: 100%;
    text-align: center;
    padding-top: 45px;
}

.login .form-title {
    margin-bottom: 20px;
    color: black;
    font-size: 19px;
    font-weight: 400 !important;
}

.login .form-subtitle {
    color: black;
    font-size: 17px;
    font-weight: 300 !important;
    padding-left: 10px;
}

.btnEntrar {
    width: 30%;
}

.login a {
    color: black !important;
    cursor: pointer;
}

.login .copyright {
    text-align: center;
    margin: 10px auto 30px 0;
    padding: 10px;
    color: black;
    font-size: 13px;
}
</style>
