import { http } from '@/http';

const path = '/documento';

class DocumentoService {

  static async findAll(idCliente, idFuncionalidade) {
    try {
      const { data } = await http.get(`${path}/all?idCliente=${idCliente}&idFuncionalidade=${idFuncionalidade}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os modelos de documento.', error);
      throw error;
    }
  }

  static async findTipoFiltroDocumento(idCliente, idFuncionalidade, idDocumento) {
    try {
      const { data } = await http.get(`${path}/tipoFiltroDocumento?idCliente=${idCliente}&idFuncionalidade=${idFuncionalidade}&idModeloDocumento=${idDocumento}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os tipos de filtro.', error);
      throw error;
    }
  }

  static async gerar(url, documento) {
    try {
      const { data } = await http.post(`${path}/gerarDocumento?url=${url}`, documento);
      return data;
    } catch (error) {
      console.log('Erro ao gerar documento.', error);
      throw error;
    }
  }

  static async imprimir(url, imprimir) {
    try {
      const config = {
        responseType: 'arraybuffer',
        headers: {
          'Content-type': 'application/json',
          Accept: '*',
        },
      };
      
      var httpResponse = await http.post(`${path}/imprimirdocumento?url=${url}`, imprimir, config);
      const {data} = httpResponse;
      const blob = new Blob([data], { type: '*' });

      const p = Promise.resolve(blob.text());
      const PromiseResult = await p;

      const blob2 = new Blob([data], { type: PromiseResult.includes('%PDF') ? 'application/pdf' : 'text/html' });
      const fileURL = window.URL.createObjectURL(blob2);
      window.open(fileURL, '_blank');

      
    } catch (error) {
      console.log('Erro ao imprimir documento.', error);
      throw error;
    }
  }

  static async findPessoa(idCliente, cpfCnpj) {
    try {
      const { data } = await http.get(`${path}/pesquisarPessoa?idCliente=${idCliente}&cpfCnpj=${cpfCnpj}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar documento.', error);
      throw error;
    }
  }

}

export default DocumentoService;