import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import VueJwtDecode from 'vue-jwt-decode'


const API_URL = '/pessoa';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUserFinan')));

class CadastroPessoaService {
    constructor() {
    }

    get currentUserValue() {
        return currentUserSubject.value;
    }
    get currentUser() {
        return currentUserSubject.asObservable();
    }

    isTokenExpired() {
        if (this.currentUserValue && this.currentUserValue.token) {
            const token = this.currentUserValue.token;
            try {
                const date = new Date(0);
                const decoded = VueJwtDecode.decode(token);
                date.setUTCSeconds(decoded.exp);
                return new Date().valueOf() > date.valueOf();
            } catch (err) {
                return false;
            }
        }
        return false;
    }

    findAllLocalidadeByFiltro(filtro) {
        return axios.get(API_URL + '/localidade/filter?filtro='+filtro, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }

    findAllBairroByFiltro(idCliente, filtro) {
        return axios.get(API_URL + '/bairro/filter?idCliente='+idCliente+'&filtro='+filtro, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }
    
    findAllLogradouroByFiltro(idCliente, filtro) {
        return axios.get(API_URL + '/logradouro/filter?idCliente='+idCliente+'&filtro='+filtro, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }
    
    findAllByCep(cep) {
        return axios.get(API_URL + '/localidade/filter?cep='+cep, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }
    
    findAllById(id) {
        return axios.get(API_URL + '/localidade/findById?id='+id, {headers: this.headers});
    }

    findAllLograByCep(cep) {
        return axios.get(API_URL + '/logradouro/findByCep?cep='+cep, {headers: this.headers});
    }

    finalizarCadastro(dados){
        return axios.post(API_URL + '/finalizarCadastro', dados);
    }

}

export default new CadastroPessoaService();